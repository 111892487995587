export const handleVerifyRecaptchaV3 = async (token, userInfo) => {
  try {
    const res = await fetch('/api/recaptcha/v3/verify', {
      method: 'POST',
      body: JSON.stringify({
        token,
        userInfo,
      }),
    })
    const resJson = await res.json()
    return resJson
  } catch (err) {
    return {}
  }
}

export default {}
