import React from 'react'
import PropTypes from 'prop-types'

import { Text, Link } from '@/components/common'

export default function RecaptchaTerms({ linkProps = {}, textProps = {}, size = 'tiny' }) {
  return (
    <Text.P
      messageProps={{
        id: 'footer.recaptchaTerms',
        values: {
          privacyLink: <Link external openTab size={size} to="https://policies.google.com/privacy" {...linkProps}>Privacy Policy</Link>,
          termsLink: <Link external openTab size={size} to="https://policies.google.com/terms" {...linkProps}>Terms of Service</Link>,
        },
      }}
      size={size}
      {...textProps}
    />
  )
}

RecaptchaTerms.propTypes = {
  linkProps: PropTypes.shape({}),
  size: PropTypes.string,
  textProps: PropTypes.shape({}),
}
