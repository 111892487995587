export const ENV = process.env.NEXT_PUBLIC_ENV

export const IS_DEV = ENV === 'development'
export const IS_PROD = ENV === 'production'

const getWebAppUrl = () => {
  switch (ENV) {
    case 'qa':
      return 'https://app-qa.neat.com'
    case 'production':
      return 'https://app.neat.com'
    default:
      return 'https://app-staging.neat.com'
  }
}

const getGoogleTagManagerId = () => {
  switch (ENV) {
    case 'production':
      return 'GTM-PZCM6D'
    default:
      return 'GTM-WDXCX6S'
  }
}

const getSquidwardApiRoot = () => {
  switch (ENV) {
    case 'qa':
      return 'https://squidward-qa.neat.com'
    case 'production':
      return 'https://squidward.neat.com'
    default:
      return 'https://squidward-staging.neat.com'
  }
}

const getGoogleSignInId = () => {
  switch (ENV) {
    case 'qa':
      return '289196470141-9o3uroubbjmlpmjhffaorvfmhtefb9h5.apps.googleusercontent.com'
    case 'production':
      return '661316390459-1d6d8h4rqk3d79sh5nhoqsc3q7ud4slk.apps.googleusercontent.com'
    default:
      return '79655950420-7advifufk6pkvemumpf2c7r70evnjfar.apps.googleusercontent.com'
  }
}

const getRecurlyId = () => {
  switch (ENV) {
    case 'qa':
      return 'ewr1-C892qOUmwfN2XU9RSuigoe'
    case 'production':
      return 'ewr1-kN2T3jHWviU3IAPWWYUuqa'
    default:
      return 'ewr1-jUsi8exmOff3fntJ2rpLMo'
  }
}

const getOrderApi = () => {
  switch (ENV) {
    case 'qa':
      return 'https://squidward-qa.neat.com'
    case 'production':
      return 'https://squidward.neat.com'
    default:
      return process.env.SQUIDWARD_API_URL
  }
}

const getOrderApiToken = () => process.env.SQUIDWARD_TOKEN

const getAppUrl = () => {
  switch (ENV) {
    case 'development':
      return process.env.NEXT_PUBLIC_APP_URL_DEV
    case 'qa':
      return process.env.NEXT_PUBLIC_APP_URL_QA
    case 'production':
      return process.env.NEXT_PUBLIC_APP_URL_PRODUCTION
    default:
      return process.env.NEXT_PUBLIC_APP_URL_STAGING
  }
}

const getBuyFlowGoogleTagManagerId = () => {
  switch (ENV) {
    case 'production':
      return 'GTM-5WRC63V'
    default:
      return 'GTM-P5GVKFG'
  }
}

const getRecaptchaSiteKey = () => {
  switch (ENV) {
    case 'qa':
      return '6Lfplg8pAAAAADiLoAb_ZYtaUh1CQeaArK_Lr4VY'
    case 'production':
      return '6Lfzlg8pAAAAAHF6c6Ob-aPs7u_-4I99VETDpNqH'
    default:
      return '6Lfslg8pAAAAABcoYrANwJl4T8jzEHIzxamyg_zU'
  }
}

const getNeatDataUrl = () => {
  switch (ENV) {
    case 'qa':
      return 'https://www-qa-data.neat.com'
    case 'production':
      return 'https://www-data.neat.com'
    default:
      return 'https://www-staging-data.neat.com'
  }
}

const getRecaptchaV3SiteKey = () => {
  switch (ENV) {
    case 'production':
      return '6LfFqJYqAAAAAGdASYlYytH1UBpDxnXjOyYM7W-j'
    default:
      return '6LeEv0UqAAAAAMZ_qzhYm2_9oGVTi8Qe0OdWlXg5'
  }
}

export const CSRF_HARD_FAIL = process.env.CSRF_HARD_FAIL === 'true'
export const SHOW_UPSELL = process.env.NEXT_PUBLIC_SHOW_UPSELL === 'true'
export const WEB_APP_URL = getWebAppUrl()
export const GTM_ID = getGoogleTagManagerId()
export const SQUIDWARD_ROOT = getSquidwardApiRoot()
export const GOOGLE_SIGN_IN_ID = getGoogleSignInId()
export const RECURLY_ID = getRecurlyId()
export const ORDER_API = getOrderApi()
export const ORDER_API_TOKEN = getOrderApiToken()
export const APP_URL = getAppUrl()
export const BUY_FLOW_GTM_ID = getBuyFlowGoogleTagManagerId()
export const RECAPTCHA_SITE_KEY = getRecaptchaSiteKey()
export const NEAT_DATA_URL = getNeatDataUrl()
export const RECAPTCHA_V3_SITE_KEY = getRecaptchaV3SiteKey()

/** Blog API Domains */
const BLOG_API_PRODUCTION = 'https://cms-wp.neat.com'
const BLOG_API_STAGING = 'https://cms-staging-wp.neat.com'

/** Deprecated Blog Domains */
const BLOG_API_PRODUCTION_DEPRECATED = 'https://cms.neat.com'
const BLOG_API_STAGING_DEPRECATED = 'https://cms-staging.neat.com'
const BLOG_API_PRODUCTION_WP_ENGINE = 'https://neatcmsprod.wpengine.com'
const BLOG_API_STAGING_WP_ENGINE = 'https://cms-staging.neat.com'

const getBlogApiUrl = () => {
  switch (ENV) {
    case 'production':
      return BLOG_API_PRODUCTION
    default:
      return BLOG_API_STAGING
  }
}

const getBlogImageUrl = () => {
  switch (ENV) {
    case 'production':
      return 'https://neat-cms-prod.s3.amazonaws.com/wp-content'
    default:
      return 'https://neat-cms-staging.s3.amazonaws.com/wp-content'
  }
}

export const BLOG_API_URL = getBlogApiUrl()
export const BLOG_IMAGE_URL = getBlogImageUrl()

export const BLOG_REDIRECTS = [
  BLOG_API_PRODUCTION,
  BLOG_API_STAGING,
  BLOG_API_PRODUCTION_WP_ENGINE,
  BLOG_API_STAGING_WP_ENGINE,
  BLOG_API_PRODUCTION_DEPRECATED,
  BLOG_API_STAGING_DEPRECATED,
]
